<template>
  <v-app >
      <pages-core-view />
  </v-app>
</template>

<script>
export default {
  name: "PagesIndex",

  components: {

    PagesCoreView: () => import("./components/core/View"),
  },

  created() {
    // this.$vuetify.theme.dark = false;
  },

  beforeDestroy() {
    // this.$vuetify.theme.dark = false;
  },
};
</script>
<style lang="scss" scoped>

</style>
